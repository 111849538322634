<template>
    <div class="configuration d-flex flex-column --vh-100">
        <HeaderMain></HeaderMain>
        <section class="options flex-fill pt-4" v-if="!restricted">
            <div class="container">
                <div class="py-2 mb-4 border-bottom">
                    <h4 class="pt-1 font-size-heading-sm text-main font-weight-normal">Configuration</h4>
                    <p>Please read the <a class="link-primary" href="#" @click.prevent="openDocumentation">White Label Documentation</a> before making any changes.</p> 
                </div>
                
                <form @keydown.enter.prevent=";">
                    <div v-for="(value, key, index) in config" :key="key">
                        <template v-if="isObject(value) && key != 'features'">
                            <h5>{{ key }}</h5>
                            <span class="d-none">{{
                                (nestedData = config[key]) && (section = key) && (sections[index] = key)
                            }}</span>
                            
                            <div 
                                class="mb-3 row"
                                v-for="(val, key) in nestedData"
                                :key="key"
                                >
                                <label class="form-label col-sm-3 col-form-label text-muted">
                                    {{ key }}
                                </label>
                                <div class="col-sm-9">
                                    <input v-if="section == 'general'"
                                        type="text"
                                        class="form-control"
                                        disabled
                                        :value="nestedData[key]"
                                        @input="updateConfig(sections[index], key, $event.target.value)"
                                    />
                                    <input v-else
                                        :type="[sections[index] == 'keys'? 'password' : 'text']"
                                        class="form-control"
                                        :value="nestedData[key]"
                                        @input="updateConfig(sections[index], key, $event.target.value)"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="text-end pt-4 pb-5">
                        <a class="btn btn-sm font-size-16 btn-pill btn-outline-secondary border-info me-2 ps-3 pe-3" @click.prevent="openDocumentation">Need Help?</a>
                        <button type="submit" class="btn btn-sm font-size-16 btn-pill btn-primary me-2 ps-3 pe-3" @click.prevent="saveConfiguration()">Save Configuration</button>
                    </div>
                </form>
            </div>
            <AppFooter></AppFooter>
        </section>
    </div>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue";


export default {
    name: "ConfigurationMain",
    components: {
        AppFooter,
    },
    data() {
        return {
            sections: [],
            localConfig: null,
            config: null
        };
    },
    computed: {
        restricted() {
            // let isRestricted = this.$route.meta.restricted || false;
            let isRestricted = this.$root.user?.user_email == this.config?.general?.email? false : true;
            return isRestricted;
        }, 
        partner() {
            return this.config;
        }
    },


    methods: {
        async getConfiguration() {
            if(this.config == null) {
                this.$store.commit('SET_LOADING_STATE', true);
                let partner = await this.$store.dispatch('getPartner');
                this.config = partner;
                this.$store.commit('SET_LOADING_STATE', false);
            }

            // console.log(this.$root.user?.user_email, this.config.general.email)
            return this.config;
        },
        updateConfig(section, key, value) {
            // console.log(section, key, value);
            this.config[section][key] = value;
        },
        async saveConfiguration(doSave) {
            
            if(!doSave) {
                this.$root.notify({
                    type: 'confirm',
                    title: 'Confirm',
                    message: 'Are you sure you want to save this configuration?',
                    callback: (okay)=> {
                        okay && this.saveConfiguration(true);
                    }
                });
                return;
            }


            this.$store.commit('SET_LOADING_STATE', true);
            let done = await this.$store.getters.wp.run('updatePartnerConfiguration', [this.config]);
            this.$store.commit('SET_LOADING_STATE', false);
            
            if(done == false) {
                this.$root.notify({
                    type: 'alert',
                    title: 'Error',
                    message: 'Unable to update your configuration.',
                    callback: function() {
                    }
                });

            } else if(done == true) {
                this.$root.notify({
                    type: 'message',
                    title: 'Yay!',
                    message: 'Your configuration has been updated. You must refresh this page to continue.',
                    callback: function() {
                        document.location.reload()
                    }
                });
            }

        },
        isObject(value) {
            return typeof value === "object" && value !== null;
        },
        openDocumentation() {
            let documentationURL = 'https://docs.google.com/document/d/19dx56nIjAS-B3JIFaU6Y5CO0Nb-7yN9Gyvs-G35Cn_0/edit#heading=h.g8971yssbep7';
            window.open(documentationURL, 'whitelabel-documentation');
        }
    },
    async mounted() {
        await this.getConfiguration();

        if(this.restricted) {
            this.$root.notify({
                type: 'alert',
                title: 'ERROR: 403 Forbidden',
                message: 'You do not have access to this page.',
                callback: ()=> {
                    this.$router.push({ path: '/' })
                }
            });
        }
        window.conf = this;
    },
};
</script>

<style >
.configuration .options {
    overflow-y: scroll;
}
</style>